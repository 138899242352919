import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Form, FormControl, Dropdown } from "react-bootstrap";

export default function Header({
  searchQuery,
  setSearchQuery,
  cart,
  user,
  setShowLoginModal,
  setShowRegisterModal,
  handleLogout,
}) {
  const [saviPoints, setSaviPoints] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`[${new Date().toISOString()}] Header mounted, user:`, user);

    const fetchSaviPoints = async () => {
      if (!user) {
        setSaviPoints(null);
        return;
      }

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log(`[${new Date().toISOString()}] Không tìm thấy token trong localStorage`);
          return;
        }

        console.log(`[${new Date().toISOString()}] Bắt đầu lấy số saviPoints từ API`);
        const response = await fetch("https://savinashop.com/api/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Lỗi khi lấy số saviPoints");
        }

        const data = await response.json();
        console.log(`[${new Date().toISOString()}] Nhận dữ liệu saviPoints:`, data.saviPoints);
        setSaviPoints(data.saviPoints || 0);
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Lỗi khi lấy saviPoints: ${error.message}`);
        setSaviPoints(0);
      }
    };

    fetchSaviPoints();
  }, [user]);

  const handleSearchChange = (e) => {
    console.log(`[${new Date().toISOString()}] Thay đổi tìm kiếm: ${e.target.value}`);
    setSearchQuery(e.target.value);
  };

  const handleLoginRegisterClick = () => {
    console.log(`[${new Date().toISOString()}] Nhấn nút Đăng nhập/Đăng ký`);
    navigate("/login-register");
  };

  const handleLogoutClick = () => {
    console.log(`[${new Date().toISOString()}] Nhấn nút đăng xuất từ header`);
    handleLogout();
    setSaviPoints(null);
  };

  return (
    <Navbar expand="lg" className="header-custom shadow-sm" fixed="top">
      <div className="container d-flex align-items-center justify-content-between">
        <Navbar.Toggle aria-controls="navbar-nav" className="custom-toggler d-lg-none">
          <i className="bi bi-list"></i>
        </Navbar.Toggle>

        <Link to="/" className="navbar-brand d-flex align-items-center mx-auto mx-lg-0">
          <img 
            src="../img/logo.png" 
            alt="Savinashop Logo" 
            className="me-2 img-fluid" 
            style={{ maxHeight: '40px', width: 'auto' }} 
          />
        </Link>

        <Link to="/cart" className="nav-link icon-link position-relative d-lg-none">
          <i className="bi bi-cart3"></i>
          {cart.length > 0 && (
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {cart.reduce((sum, item) => sum + (item.quantity || 1), 0)}
            </span>
          )}
        </Link>

        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            <Link to="/" className="nav-link text-white">Trang chủ</Link>
            <Link to="/tin-tuc" className="nav-link text-white">Tin tức</Link>
          </Nav>

          <Form className="search-form mx-auto d-flex align-items-center d-none d-lg-flex">
            <div className="search-wrapper position-relative">
              <i className="bi bi-search search-icon"></i>
              <FormControl
                type="text"
                placeholder="Tìm kiếm sản phẩm..."
                className="search-input"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </Form>

          <Nav className="ms-auto d-flex align-items-center">
            <Link to="/cart" className="nav-link icon-link position-relative d-none d-lg-flex">
              <i className="bi bi-cart3"></i>
              <span className="icon-label d-none d-lg-block">Giỏ hàng</span>
              {cart.length > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cart.reduce((sum, item) => sum + (item.quantity || 1), 0)}
                </span>
              )}
            </Link>

            {user && saviPoints !== null && (
              <div className="nav-link icon-link text-white d-flex align-items-center">
                <i className="bi bi-coin me-1"></i>
                <span className="points d-none d-lg-block">
                  {saviPoints.toLocaleString()} points
                </span>
                <span className="points-mobile d-lg-none">
                  {saviPoints > 999 ? `${(saviPoints / 1000).toFixed(1)}K` : saviPoints}
                </span>
              </div>
            )}

            {user ? (
              <Dropdown>
                <Dropdown.Toggle className="nav-link icon-link text-white" id="dropdown-user">
                  <i className="bi bi-person-circle"></i>
                  <span className="icon-label d-none d-lg-block">{user.email}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item as={Link} to="/orders">
                    Lịch sử mua hàng
                  </Dropdown.Item>
                  {(user.role === "admin" || user.role === "staff") && (
                    <Dropdown.Item as={Link} to="/admin">
                      Quản lý
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={handleLogoutClick}>Đăng xuất</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <button
                className="nav-link icon-link text-white"
                onClick={handleLoginRegisterClick}
              >
                <i className="bi bi-person-circle"></i>
                <span className="icon-label d-none d-lg-block">Đăng nhập/Đăng ký</span>
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>

      <div className="container d-lg-none">
        <Form className="search-form d-flex align-items-center">
          <div className="search-wrapper position-relative">
            <i className="bi bi-search search-icon"></i>
            <FormControl
              type="text"
              placeholder="Tìm kiếm..."
              className="search-input"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </Form>
      </div>
    </Navbar>
  );
}